import { Camera } from '@mediapipe/camera_utils';
import { drawConnectors, drawLandmarks } from '@mediapipe/drawing_utils';
import { Hands, HAND_CONNECTIONS } from '@mediapipe/hands';
import * as dat from 'dat.gui';
import { create, all } from 'mathjs';
import modelParams from './gesture_recognizer_model.json';
import labelMap from './gesture_recognizer_model_label_map.json';

const math = create(all);

class Keypoints {
    constructor(landmarks) {
        if (Array.isArray(landmarks)) {
            // Convert MediaPipe landmarks to a matrix
            this.points = math.matrix(landmarks.map(lm => [lm.x, lm.y]));
        } else if (math.isMatrix(landmarks)) {
            this.points = landmarks;
        } else {
            throw new Error('Invalid input: expected array of landmarks or math.js matrix');
        }
    }

    _getScaledPoints(width, height) {
        const scaleMatrix = math.matrix([
            [width, 0],
            [0, height]
        ]);
        return math.multiply(this.points, scaleMatrix);
    }

    scale(width, height) {
        this.points = this._getScaledPoints(width, height);
        return this;
    }

    scaled(width, height) {
        return new Keypoints(this._getScaledPoints(width, height));
    }

    rotated(degrees, origin = { x: 0, y: 0 }) {
        const radians = (degrees * Math.PI) / 180;
        const cos = Math.cos(radians);
        const sin = Math.sin(radians);

        const rotationMatrix = math.matrix([
            [cos, -sin],
            [sin, cos]
        ]);

        // Translate points to origin
        const translatedPoints = math.subtract(this.points, origin);
        
        // Rotate translated points
        const rotatedTranslatedPoints = math.multiply(translatedPoints, rotationMatrix);
        
        // Translate points back
        const rotatedPoints = math.add(rotatedTranslatedPoints, origin);

        return new Keypoints(rotatedPoints);
    }

    flipHorizontal() {
        const bbox = this.boundingBox();
        const centerX = (bbox.minX + bbox.maxX) / 2;

        const flippedPoints = this.points.map((value, index) => {
            if (index[1] === 0) { // x coordinate
                return 2 * centerX - value;
            } else { // y coordinate
                return value;
            }
        });

        return new Keypoints(flippedPoints);
    }

    alignedVertical() {
        // Get the first and last points (rows 0 and 9)
        const firstPoint = math.subset(this.points, math.index(0, [0, 1]));
        const lastPoint = math.subset(this.points, math.index(9, [0, 1]));

        // Calculate the angle between the line defined by these two points and the vertical axis
        const diff = math.subtract(lastPoint, firstPoint);
        const dx = diff.get([0, 0]);
        const dy = diff.get([0, 1]);
        const angle = Math.atan2(dx, -dy); // Negative dy because y-axis is inverted in most computer graphics

        const angleDegrees = angle * (180 / Math.PI);

        return this.rotated(angleDegrees, lastPoint);
    }

    normalized() {
        const xCoords = this.points.toArray().map(point => point[0]);
        const yCoords = this.points.toArray().map(point => point[1]);

        const meanX = math.mean(xCoords);
        const meanY = math.mean(yCoords);

        const rangeX = math.max(xCoords) - math.min(xCoords);
        const rangeY = math.max(yCoords) - math.min(yCoords);
        const maxRange = math.max(rangeX, rangeY, 1e-6); // Avoid division by zero

        const normalizedPoints = this.points.map((value, index) => {
            if (index[1] === 0) { // x coordinate
                return (value - meanX) / maxRange;
            } else { // y coordinate
                return (value - meanY) / maxRange;
            }
        });

        return new Keypoints(normalizedPoints);
    }

    flatten() {
        return math.flatten(this.points);
    }

    boundingBox() {
        const pointsArray = this.points.toArray();
        let minX = Infinity, minY = Infinity;
        let maxX = -Infinity, maxY = -Infinity;

        for (const [x, y] of pointsArray) {
            minX = Math.min(minX, x);
            minY = Math.min(minY, y);
            maxX = Math.max(maxX, x);
            maxY = Math.max(maxY, y);
        }

        return {
            minX,
            minY,
            maxX,
            maxY,
            width: maxX - minX,
            height: maxY - minY
        };
    }

    toMediaPipe(canvasWidth, canvasHeight) {
        return this.points.toArray().map(([x, y]) => ({
            x: x / canvasWidth,
            y: y / canvasHeight,
            z: 0
        }));
    }
}

class LogisticRegression {
    constructor() {
        this.coefficients = null;
        this.intercept = null;
        this.classes = null;
    }

    sigmoid(z) {
        return 1 / (1 + Math.exp(-z));
    }

    predictProba(X) {
        if (!this.coefficients || !this.intercept) {
            throw new Error("Model is not trained or loaded");
        }

        const inputMatrix = math.matrix(X);
        const coefMatrix = math.transpose(math.matrix(this.coefficients));
        const interceptMatrix = math.matrix(this.intercept);

        const z = math.add(math.multiply(inputMatrix, coefMatrix), interceptMatrix);
        return z.map(this.sigmoid);
    }

    predict(X) {
        const probas = this.predictProba(X).toArray();
        const max_proba = Math.max(...probas);
        const prediction = probas.indexOf(max_proba);
        return { prediction, max_proba };
    }

    loadFromJSON(modelParams) {
        this.coefficients = modelParams.coefficients;
        this.intercept = modelParams.intercept;
        this.classes = modelParams.classes;
    }
}

class Recognizer {
    constructor() {
        this.model = new LogisticRegression();
        this.labelMap = labelMap;
        this.inverseLabelMap = null;
        this.isLoaded = false;
    }

    async load() {
        try {
            this.model.loadFromJSON(modelParams);
            this.createInverseLabelMap();
            this.isLoaded = true;
            console.log("Model and label map loaded");
        } catch (e) {
            console.error(`Failed to load model: ${e}`);
        }
    }

    createInverseLabelMap() {
        this.inverseLabelMap = Object.fromEntries(
            Object.entries(this.labelMap).map(([key, value]) => [value, key])
        );
    }

    async recognize(keypoints) {
        if (!this.isLoaded) {
            console.log("Model not loaded yet");
            return { label: "Model not loaded", probability: 0 };
        }

        if (!keypoints || keypoints.length === 0) {
            console.log("No landmarks detected");
            return { label: "No hand detected", probability: 0 };
        }

        try {
            const flattenedLandmarks = keypoints.flatten();

            // Run prediction
            const { prediction, max_proba } = this.model.predict(flattenedLandmarks.toArray());
            
            // Get the label name from the inverse label map
            const labelName = this.inverseLabelMap[prediction] || "Unknown gesture";

            return { label: labelName || "Unknown gesture", probability: max_proba };
        } catch (error) {
            console.error("Error during recognition:", error);
            return "Error in recognition";
        }
    }
}

function drawLabel(ctx, text, x, y) {
    ctx.font = '16px Arial';
    ctx.fillStyle = 'white';
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 3;
    ctx.strokeText(text, x, y);
    ctx.fillText(text, x, y);
}

const videoElement = document.getElementsByClassName('input_video')[0];
const canvasElement = document.getElementsByClassName('output_canvas')[0];
let canvasCtx = canvasElement.getContext('2d');
const recognizer = new Recognizer();

const gui = new dat.GUI();
const settings = {
    maxNumHands: 2,
    modelComplexity: 1,
    minDetectionConfidence: 0.5,
    minTrackingConfidence: 0.5,
    selectedCamera: null,
    flipHorizontal: false,
    alignVertical: true,
    drawProcessedKeypoints: false
};

// Create separate folders for general and MediaPipe settings
const generalFolder = gui.addFolder('General');
const mediaPipeFolder = gui.addFolder('MediaPipe');
const gesturesFolder = gui.addFolder('Gestures');

const hands = new Hands({
    locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/hands@0.4/${file}`
});

// Function to update hands options
function updateHandsOptions() {
    hands.setOptions({
        maxNumHands: settings.maxNumHands,
        modelComplexity: settings.modelComplexity,
        minDetectionConfidence: settings.minDetectionConfidence,
        minTrackingConfidence: settings.minTrackingConfidence
    });
}

// Function to update camera source
async function updateCameraSource(deviceId) {
    const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: deviceId } }
    });
    videoElement.srcObject = stream;
}

// Function to populate camera dropdown
async function populateCameraDropdown() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(device => device.kind === 'videoinput');
    
    // Create an object with camera names as keys and deviceIds as values
    const cameraOptions = {};
    videoDevices.forEach((device, index) => {
        cameraOptions[device.label || `Camera ${index + 1}`] = device.deviceId;
    });

    // Remove existing camera dropdown if it exists
    if (settings.cameraDropdown) {
        generalFolder.remove(settings.cameraDropdown);
    }

    // Add the new dropdown to the general settings folder
    settings.cameraDropdown = generalFolder.add(settings, 'selectedCamera', cameraOptions)
        .name('Select Camera')
        .onChange(async (deviceId) => {
            await updateCameraSource(deviceId);
        });

    // Set the initial camera
    if (videoDevices.length > 0) {
        settings.selectedCamera = videoDevices[0].deviceId;
        await updateCameraSource(settings.selectedCamera);
    }
}

// Initial setup
updateHandsOptions();
populateCameraDropdown();

// Add GUI controls to the MediaPipe folder
mediaPipeFolder.add(settings, 'maxNumHands', 1, 2, 1).onChange(updateHandsOptions);
mediaPipeFolder.add(settings, 'modelComplexity', 0, 1, 1).onChange(updateHandsOptions);
mediaPipeFolder.add(settings, 'minDetectionConfidence', 0, 1, 0.05).onChange(updateHandsOptions);
mediaPipeFolder.add(settings, 'minTrackingConfidence', 0, 1, 0.05).onChange(updateHandsOptions);

gesturesFolder.add(settings, 'flipHorizontal').name('Flip Horizontal');
gesturesFolder.add(settings, 'alignVertical').name('Align Vertical');
gesturesFolder.add(settings, 'drawProcessedKeypoints').name('Draw Processed Keypoints');

hands.onResults(onResults);

function onResults(results) {
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);
    
    if (results.multiHandLandmarks && results.multiHandLandmarks.length > 0) {
        let allKeypoints = [];
        for (let i = 0; i < results.multiHandLandmarks.length; i++) {
            const landmarks = results.multiHandLandmarks[i];
            const handedness = results.multiHandedness[i];
            
            drawConnectors(canvasCtx, landmarks, HAND_CONNECTIONS,
                           {color: '#00FF00', lineWidth: 5});
            drawLandmarks(canvasCtx, landmarks, {color: '#FF0000', lineWidth: 2});
            
            const keypoints = new Keypoints(landmarks);
            const scaledKeypoints = keypoints.scaled(canvasElement.width, canvasElement.height);
            allKeypoints.push(scaledKeypoints);
        }
        
        if (allKeypoints.length == 2) {
            const firstKeypoints = allKeypoints[0];
            const secondKeypoints = allKeypoints[1];

            if (firstKeypoints.boundingBox().minX < secondKeypoints.boundingBox().minX) {
                allKeypoints[0] = firstKeypoints.flipHorizontal();
            } else {
                allKeypoints[1] = secondKeypoints.flipHorizontal();
            }
        }

        for (let i = 0; i < allKeypoints.length; i++) {
            const keypoints = allKeypoints[i];
            let processedKeypoints = keypoints;
            
            if (settings.flipHorizontal) {
                processedKeypoints = processedKeypoints.flipHorizontal();
            }
            
            if (settings.alignVertical) {
                processedKeypoints = processedKeypoints.alignedVertical();
            }

            // Draw processed keypoints and connections using MediaPipe's functions if enabled
            if (settings.drawProcessedKeypoints) {
                const processedLandmarks = processedKeypoints.toMediaPipe(canvasElement.width, canvasElement.height);
                drawConnectors(canvasCtx, processedLandmarks, HAND_CONNECTIONS, {
                    color: '#00CCCC',
                    lineWidth: 2
                });
                drawLandmarks(canvasCtx, processedLandmarks, {
                    color: '#00CCCC',
                    lineWidth: 2,
                    radius: 3
                });
            }

            let normalizedKeypoints = processedKeypoints.normalized();

            const bbox = keypoints.boundingBox();
            const handedness = results.multiHandedness[i];
            recognizer.recognize(normalizedKeypoints)
            .then(({ label, probability }) => {
                const gestureText = `${label} ${probability.toFixed(2)}`;
                const handednessText = `${handedness.label} ${handedness.score.toFixed(2)}`;
                drawLabel(canvasCtx, gestureText, bbox.minX, bbox.minY - 25);
                drawLabel(canvasCtx, handednessText, bbox.minX, bbox.minY - 5);
            });
        }
    }
    canvasCtx.restore();
}

const camera = new Camera(videoElement, {
    onFrame: async () => {
        await hands.send({image: videoElement});
    },
    width: 1280,
    height: 720
});
camera.start();

// Load the recognizer model when the page loads
window.addEventListener('load', async () => {
    await recognizer.load();
    await populateCameraDropdown();
});
